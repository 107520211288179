const { gql } = require("@apollo/client")

const GET_ACTION_PLAN_STEP = gql`
  query getActionPlanStep($id: String!, $preview: Boolean!, $surveyId: String) {
    getActionPlanStep(id: $id, preview: $preview, surveyId: $surveyId) {
      actionPlanStep {
        actionPlanId
        createdAt
        default
        id
        title
        type
        updatedAt
        questions {
          sixStepsQuestionId
          title
          body {
            description
            suggestedActions
            suggestedPhrases
            resources {
              description
              mediaType
              title
              url
            }
            questions {
              answers
              id
              title
            }
          }
        }
      }
      message
      status
    }
  }
`

export default GET_ACTION_PLAN_STEP
