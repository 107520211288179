// TODO: Feature names need to come out of single source of truth.
const getFeatures = (features, paths, wonAward, bespokeSurveyLicence) => {
  const unlockFeature = name =>
    features?.find(feature => feature.object_type === name)

  return [
    {
      name: "Best Places to Work Awards",
      description:
        "Select from below to: enter the awards, share survey link and monitor survey completion or to access your survey results.",
      iconConfig: {
        name: "trophy",
        color: "#B02E2E",
        backgroundColor: "#F3E0E0"
      },
      unLocked: true,
      primaryButtonConfig: {
        text: "Enter Here",
        linkTo: paths.awards.index
      },
      secondaryButtonConfig: {
        text: "Live Surveys",
        linkTo: paths.surveys.liveSurveys
      },
      tertiaryButtonConfig: {
        text: "Results",
        linkTo: paths.reports.index
      },
      bgColor: "#CDEAFF"
    },
    {
      name: "Bespoke Surveys",
      description:
        "For our customers on bespoke licences, supported by our client success team. Create your own survey, track a live response rate and view your survey results.",
      iconConfig: {
        name: "library_books",
        color: "#E66F3D",
        backgroundColor: "#FBE9E2"
      },
      unLocked: bespokeSurveyLicence,
      primaryButtonConfig: {
        text: "Create survey",
        linkTo: paths.surveys.surveyCreation.index
      },
      secondaryButtonConfig: {
        text: "View survey results",
        linkTo: paths.reports.index
      },
      lockedButtonConfig: {
        text: "Contact us",
        linkTo: paths.contactUs,
        external: true
      }
    },
    {
      name: "Self Serve Surveys",
      description:
        "For our self service customers. Create and launch your own survey and view your survey results.",
      iconConfig: {
        name: "library_books",
        color: "#E66F3D",
        backgroundColor: "#FBE9E2"
      },
      unLocked: true,
      primaryButtonConfig: {
        text: "Create Survey ",
        linkTo: paths.selfServe.index
      },
      secondaryButtonConfig: {
        text: "View survey results",
        linkTo: paths.reports.index
      }
    },
    {
      name: "World’s Happiest Workplaces Logo and Certificate",
      unLocked: true,
      description:
        "Has your organisation been recognised as a World’s Happiest Workplace? Download your logo and certificate.",
      iconConfig: {
        name: "research",
        color: "#E66F3D",
        backgroundColor: "#FBE9E2"
      },
      primaryButtonConfig: {
        text: "View Logos",
        linkTo: paths.logosAndCertifications.index
      },
      secondaryButtonConfig: {
        text: "Buy Now",
        linkTo: paths.logosAndCertifications.buyNow,
        hidden: unlockFeature("happiest-workplaces-awards") || !wonAward
      }
    },
    {
      name: "Claim your Business Information Page",
      description:
        "Claim and populate your Business Information Page to promote your organisation as a happy place to work to our job seekers.",
      iconConfig: {
        name: "store_mall_directory",
        color: "#B02E2E",
        backgroundColor: "#F3E0E0"
      },
      unLocked: unlockFeature("bip"),
      primaryButtonConfig: {
        text: "Manage my BIP",
        linkTo: paths.home.businessInformationHub
      },
      lockedButtonConfig: {
        text: "Unlock BIP",
        linkTo: paths.home.businessInformationHub
      }
    },
    {
      name: "Post Jobs",
      unLocked: unlockFeature("jobs-board"),
      description:
        "Post your vacancies to hundreds of thousands of job seekers on WorkL to secure the perfect candidate. Post jobs or use our specific candidate matching service. ",
      iconConfig: {
        name: "briefcase",
        color: "#173C5A",
        backgroundColor: "#DCE2E6"
      },
      primaryButtonConfig: {
        text: "Manage my jobs",
        linkTo: paths.home.jobs.liveJobs
      },
      lockedButtonConfig: {
        text: "Unlock Jobs",
        linkTo: paths.home.jobs.liveJobs
      }
    },
    {
      name: "Post Events",
      unLocked: unlockFeature("events"),
      description:
        "Create, post and promote your events to our audience of hundreds of thousands on WorkL.",
      iconConfig: {
        name: "date_range",
        color: "#2EB0A1",
        backgroundColor: "#E0F3F1"
      },
      primaryButtonConfig: {
        text: "Manage events",
        linkTo: paths.home.events.index
      },
      lockedButtonConfig: {
        text: "Unlock Events",
        linkTo: paths.home.events.index
      }
    },
    {
      name: "Find Leads",
      unLocked: unlockFeature("leads"),
      description:
        "Identify and secure leads by promoting your services to our audience of hundreds of thousands on WorkL.",

      iconConfig: {
        name: "person_pin",
        color: "#6D74A8",
        backgroundColor: "#E9EAF2"
      },
      primaryButtonConfig: {
        text: "Discover leads ",
        linkTo: paths.home.leadGeneration
      },
      lockedButtonConfig: {
        text: "Unlock Leads",
        linkTo: paths.home.leadGeneration
      }
    }
  ]
}

export default getFeatures
