import { IconButton } from "@engaging-tech/components"
import React from "react"
import { Tooltip } from "react-tooltip"

const SurveyIconButtons = ({ redirectToEdit, handleCopy, getData, id }) => {
  return (
    <>
      <IconButton
        iconColor="dark.2"
        icon="edit"
        onClick={redirectToEdit}
        data-tooltip-id="edit-survey-icon-button"
        data-tooltip-content="Edit survey"
      />
      <Tooltip id="edit-survey-icon-button" />
      {/*  */}
      <IconButton
        iconColor="dark.2"
        icon="content_copy"
        onClick={handleCopy}
        data-tooltip-id="copy-survey-icon-button"
        data-tooltip-content="Copy survey link"
      />
      <Tooltip id="copy-survey-icon-button" />
      {/*  */}
      <IconButton
        iconColor="dark.2"
        icon="refresh"
        onClick={() => {
          getData({ surveyId: id })
        }}
        data-tooltip-id="refresh-survey-icon-button"
        data-tooltip-content="Refresh response rates"
      />
      <Tooltip id="refresh-survey-icon-button" />
    </>
  )
}

export default SurveyIconButtons
