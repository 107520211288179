import { Button, CustomHooks } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import ReactPDF, { pdf } from "@react-pdf/renderer"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { getPdfDownloadKey } from "../../account/store/account.selectors"
import ActionPlan from "../helpers/ActionPlan"
import generatePDF, { generateDownload } from "../helpers/generatePDF"
import ExternalReportItemsRender from "./ExternalReportItemsRender"

const exportTypes = {
  print: "PRINT",
  download: "DOWNLOAD"
}

// Legacy handle click process for any working features
const handlePDFGeneration = ({
  attemptNumber = 1,
  exportType,
  updatePDFProgress,
  updatePdfDownloading,
  filters,
  subSection,
  title,
  theme,
  downloadingRef,
  isSafari,
  device
}) => {
  updatePdfDownloading(true)
  const reportItems = document.querySelectorAll(".report-item-exporting")

  if (
    reportItems.length > 0 &&
    reportItems[0].offsetWidth === 1000 &&
    downloadingRef.current &&
    attemptNumber !== 1
  ) {
    if (typeof window !== "undefined") {
      window.scroll(0, 0)
    }
    if (isSafari || device !== "DESKTOP") {
      setTimeout(() => {
        updatePdfDownloading(false)
      }, 1000)

      window.print()
    } else {
      generatePDF({
        pages: reportItems,
        theme,
        downloadingRef,
        updatePdfDownloading,
        title,
        exportType,
        subSection,
        filters,
        updatePDFProgress
      })
    }
  } else if (attemptNumber < 5) {
    setTimeout(
      () =>
        handlePDFGeneration({
          attemptNumber: attemptNumber + 1,
          exportType,
          updatePDFProgress,
          updatePdfDownloading,
          filters,
          subSection,
          title,
          theme,
          downloadingRef,
          device
        }),
      1000
    )
  } else {
    updatePdfDownloading(false)
  }
}

// Updated version
const handlePdfGeneration = async ({
  updatePdfDownloading,
  isSafari,
  device,
  render,
  exportType,
  exportTypes,
  title
}) => {
  updatePdfDownloading(true)
  if (typeof window !== "undefined") window.scroll(0, 0)

  if (isSafari || device !== "DESKTOP") {
    setTimeout(() => {
      updatePdfDownloading(false)
    }, 1000)

    window.print()
  } else {
    const blob = await pdf(render).toBlob()
    generateDownload(exportType, exportTypes, blob, title)
    updatePdfDownloading(false)
  }
}

// Legacy one pager function
const handleClickOnePager = async ({
  attemptNumber = 1,
  exportType,
  updatePdfDownloading,
  downloadingRef,
  theme,
  isSafari,
  device,
  title,
  subSection,
  filters,
  updatePDFProgress
}) => {
  updatePdfDownloading(true)

  const reportItems = document.querySelectorAll(".one-page-exporting")

  if (downloadingRef.current && attemptNumber !== 1) {
    if (typeof window !== "undefined") {
      window.scroll(0, 0)
    }
    if (isSafari || device !== "DESKTOP") {
      setTimeout(() => {
        updatePdfDownloading(false)
      }, 1000)

      window.print()
    } else {
      generatePDF({
        pages: reportItems,
        theme,
        downloadingRef,
        updatePdfDownloading,
        title,
        exportType,
        subSection,
        filters,
        updatePDFProgress
      })
    }
  } else if (attemptNumber < 5) {
    setTimeout(
      () =>
        handleClickOnePager({
          attemptNumber: attemptNumber + 1,
          exportType,
          updatePdfDownloading,
          downloadingRef,
          theme,
          isSafari,
          device,
          title,
          subSection,
          filters,
          updatePDFProgress
        }),
      1000
    )
  } else {
    updatePdfDownloading(false)
  }
}

// The print button is always present, but if on safari or mobile/tablet, then it will use normal printing process. Otherwise it would perform PDF export and open in print mode
// The PDF export button is not present on safari or mobile/tablet.

const DownloadPDF = ({
  title,
  subSection,
  isPdfDownloading,
  filters,
  updatePdfDownloading,
  reportComponents,
  updatePDFProgress,
  surveyType
}) => {
  const downloadingRef = useRef(isPdfDownloading)
  const theme = CustomHooks.useStyledTheme()
  const device = CustomHooks.useClientDevice()
  const pdfKey = useSelector(getPdfDownloadKey)

  useEffect(() => {
    downloadingRef.current = isPdfDownloading
  }, [isPdfDownloading])

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const handleClick = exportType => {
    switch (pdfKey) {
      case "action-plan": {
        return handlePdfGeneration({
          updatePdfDownloading,
          device,
          isSafari,
          render: <ActionPlan title={title} />,
          exportType,
          exportTypes,
          title
        })
      }

      default: {
        handlePDFGeneration({
          exportType,
          updatePDFProgress,
          updatePdfDownloading,
          filters,
          subSection,
          title,
          theme,
          downloadingRef,
          device
        })
      }
    }
  }

  return (
    <>
      {isPdfDownloading && (
        <ExternalReportItemsRender reportComponents={reportComponents} />
      )}
      <Button
        variant="outline.secondary.0"
        minWidth={[175, 200]}
        color="secondary.0"
        leadingIcon={isSafari ? "print" : "cloud_download"}
        fontSize={[2, 3]}
        elevation={0}
        m="6px"
        px={3}
        fontWeight={500}
        onClick={() => handleClick({ exportType: exportTypes.print })}
      >
        Print
      </Button>

      {!isSafari && device === "DESKTOP" && (
        <>
          <Button
            variant="outline.secondary.0"
            minWidth={[175, 200]}
            color="secondary.0"
            leadingIcon="cloud_download"
            fontSize={[2, 3]}
            m="6px"
            elevation={0}
            mt={[0, 2]}
            px={3}
            fontWeight={500}
            onClick={() => handleClick({ exportType: exportTypes.download })}
          >
            Download PDF
          </Button>
          {(surveyType.includes("organisation") ||
            surveyType.includes("awards")) && (
            <Button
              variant="outline.secondary.0"
              minWidth={[175, 200]}
              color="secondary.0"
              leadingIcon="cloud_download"
              fontSize={[2, 3]}
              m="6px"
              elevation={0}
              mt={[0, 2]}
              px={3}
              fontWeight={500}
              onClick={() =>
                handleClickOnePager({
                  exportType: exportTypes.download,
                  updatePdfDownloading,
                  downloadingRef,
                  theme,
                  isSafari,
                  device,
                  title,
                  subSection,
                  filters,
                  updatePDFProgress
                })
              }
            >
              One Page Report
            </Button>
          )}
        </>
      )}
    </>
  )
}
export default DownloadPDF
