/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { OrgGuard } from "../../features/account"
import {
  getPurchasedCurrentLogo,
  getWonAward
} from "../../features/account/store/account.selectors"
import ViewBuyOption from "../../features/logos/views/ViewBuyOption"
import DisplayProduct from "../../features/ui/components/DisplayProduct"
import PageLayout from "../../layouts/PageLayout"
import addParams from "../../utils/addParams"

const Banner = ({ title }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    bg="secondary.0"
    color="light.0"
    width={1 / 1}
    minHeight="300px"
    px={3}
    py={5}
  >
    <Text
      fontSize={[7, 8]}
      fontWeight={700}
      color="light.0"
      textAlign="center"
      mb={2}
      mt={5}
    >
      {title}
    </Text>
  </Flex>
)

const BuyNow = () => {
  const title = "World’s Happiest Workplaces Logo and Certificate"

  const [entitlement, setEntitlement] = useState(null)

  const wonAward = useSelector(getWonAward)
  const purchasedCurrentLogo = useSelector(getPurchasedCurrentLogo)

  useEffect(() => {
    addParams(false, getConfig().entitlements.logos, "type")
  }, [])

  useEffect(() => {
    setEntitlement(new URLSearchParams(window.location.search).get("type"))
  }, [window.location.search])

  if (!wonAward || purchasedCurrentLogo)
    return (
      <Text width={1 / 1} textAlign="center">
        404
      </Text>
    )

  return (
    <OrgGuard>
      <PageLayout banner={() => <Banner title={title} />}>
        <ViewBuyOption />
        <Box mt={6}>{entitlement && <DisplayProduct hideMessage />}</Box>
      </PageLayout>
    </OrgGuard>
  )
}

export default BuyNow
