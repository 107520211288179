import { Box, Flex, H2, Text } from "@engaging-tech/components"
import DOMPurify from "dompurify"
import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"

import { getLabelData } from "../../features/Reports/components/ScoreLabel"
import CircularScore from "./circular-score"

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  overflow-x: hidden;

  padding: 16px;
  margin-top: 50px @media only screen and (min-width: 768px) {
    padding: 0;
  }
`

const ActionPlanStepTitle = styled.h1`
  width: 100%;

  font-size: 42px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 4px;

  color: #173c5a;
`

const ActionPlanStepWrapper = styled.div`
  display: flex;
  margin-top: ${({ questionIndex }) => (questionIndex === 0 ? "10px" : "50px")};
  flex-direction: ${({ questionIndex }) =>
    questionIndex === 0 ? "column-reverse" : "column"};
  justify-content: space-around;

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
  }
`

const ActionPlanStepContainer = styled.div`
  width: 100%;

  @media only screen and (min-width: 1280px) {
    max-width: 688px;
  }
`

const ActionPlanInnerStepContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

const ActionPlanTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;

  color: #000000cc;

  margin-top: 0px;
  margin-bottom: 0px;

  word-wrap: break-word;
  white-space: pre-wrap;
`
const ActionPlanScoreText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;
`
const ActionPlanScoreSpan = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: ${({ color }) => color};
`
const ActionPlanFixedText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin-top: 16px;

  word-wrap: break-word;
  white-space: pre-wrap;
`

const OrderedList = styled.ol`
  list-style-type: decimal;

  padding-left: 16px;
  margin-top: 0;

  width: fit-content;
`

const OrderedListItem = styled.li`
  margin-top: 16px;
  overflow-x: hidden;
`

const UnorderedList = styled.ul`
  list-style-type: lower-alpha;
`

const SuggestedActionsContainer = styled.div`
  background-color: #f0f8ff;

  display: flex;
  flex-direction: column;

  margin-top: 15px;

  width: fit-content;
  max-width: max-content;

  padding: 16px 20px;

  border-radius: 4px;
`

const SuggestedActionsTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: #00000099;
`

const SuggestedPhrasesContainer = styled.div`
  background-color: #e4fdf0;

  margin-top: 28px;

  display: flex;
  flex-direction: column;

  padding: 16px 20px;

  border-radius: 4px;
`

const ScoreContainerWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  margin-top: 24px;

  @media only screen and (min-width: 1280px) {
    margin-top: 0;
  }
`

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 368px;
`

const ResourcesContainerDesktopWrapper = styled.div`
  display: none;

  @media only screen and (min-width: 1280px) {
    display: flex;
  }
`

const ResourcesContainerMobileWrapper = styled.div`
  display: block;

  @media only screen and (min-width: 1280px) {
    display: none;
  }
`

const ResourcesContainer = styled(Box)`
  background-color: #dfe8e9;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 300px;

  width: 100%;

  padding: 20px 22px;
`

const containsText = text =>
  text ? !!text.replace(/<\/?[^>]+(>|$)/g, "") : false

const ActionPlanBody = ({
  question,
  index,
  heading,
  preview,
  exportingPDF
}) => {
  const resourcesRef = useRef(null)

  useLayoutEffect(() => {
    const questionWrapper = document.getElementById(
      `${question?.sixStepsQuestionId}-${index}-action-plan-step-container`
    )

    const container = resourcesRef.current
    if (container) {
      container.style.maxHeight = `${questionWrapper.clientHeight}px`
    }
  }, [question?.sixStepsQuestionId, index])

  return (
    <ActionPlanStepWrapper
      key={index}
      questionIndex={index}
      className="action-plan-question-page"
    >
      <ActionPlanStepContainer>
        <ActionPlanInnerStepContainer
          id={`${question?.sixStepsQuestionId}-${index}-action-plan-step-container`}
        >
          {exportingPDF && heading ? (
            <H2 color="dark.1" width="auto" fontWeight="700" fontSize={5}>
              {heading}
            </H2>
          ) : null}
          <ActionPlanTitle>{question.title}</ActionPlanTitle>
          <ActionPlanScoreText>
            Your score:{" "}
            <ActionPlanScoreSpan
              color={preview ? "#00000099" : getLabelData(question.score).color}
            >
              {preview || !question?.score || question.score === -1
                ? "NA"
                : `${question.score}%`}
            </ActionPlanScoreSpan>
          </ActionPlanScoreText>
          {containsText(question.body.description) && (
            <ActionPlanFixedText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.body.description)
              }}
            />
          )}
          <ActionStepPreviewQuestions questions={question.body.questions} />
          <div>
            {containsText(question.body.suggestedActions) && (
              <SuggestedActionsContainer>
                <SuggestedActionsTitle>
                  Suggested Actions:
                </SuggestedActionsTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question.body.suggestedActions)
                  }}
                />
              </SuggestedActionsContainer>
            )}
            {containsText(question.body.suggestedPhrases) && (
              <SuggestedPhrasesContainer>
                <SuggestedActionsTitle>
                  Suggested Phrases:
                </SuggestedActionsTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question.body.suggestedPhrases)
                  }}
                />
              </SuggestedPhrasesContainer>
            )}

            {!!question.body.resources?.length && (
              <ResourcesContainerMobileWrapper>
                <ResourcesContainerComponent
                  question={question}
                  resourcesRef={resourcesRef}
                />
              </ResourcesContainerMobileWrapper>
            )}
          </div>
        </ActionPlanInnerStepContainer>
      </ActionPlanStepContainer>

      <ScoreContainerWrapper width="fit-content">
        <ScoreContainer>
          {!!question.body.resources?.length ? (
            <ResourcesContainerDesktopWrapper>
              <ResourcesContainerComponent
                question={question}
                resourcesRef={resourcesRef}
              />
            </ResourcesContainerDesktopWrapper>
          ) : (
            <ResourcesContainerDesktopWrapper>
              <Box width="368px" height="10px" />
            </ResourcesContainerDesktopWrapper>
          )}
        </ScoreContainer>
      </ScoreContainerWrapper>
    </ActionPlanStepWrapper>
  )
}

export function ActionPlanStepPreview({
  preview = false,
  step,
  exportingPDF,
  heading
}) {
  return (
    <RootContainer className="action-plan-step-container">
      <Flex
        flexDirection="column"
        style={{
          borderBottom: "1px solid lightgray",
          marginBottom: "20px",
          paddingBottom: "20px"
        }}
      >
        <ActionPlanStepTitle>{step.title}</ActionPlanStepTitle>
        <ScoreContainerWrapper width={1 / 1}>
          <ScoreContainer>
            <CircularScore score={step.score} exportingPDF={exportingPDF} />
          </ScoreContainer>
        </ScoreContainerWrapper>
      </Flex>
      {step.questions.map((question, index) => (
        <ActionPlanBody
          question={question}
          index={index}
          heading={heading}
          preview={preview}
          exportingPDF={exportingPDF}
        />
      ))}
    </RootContainer>
  )
}

function ResourcesContainerComponent({ question, resourcesRef }) {
  return (
    <ResourcesContainer ref={resourcesRef}>
      <Text
        fontSize="16px"
        fontWeight={700}
        lineHeight="20px"
        color="#00000099"
      >
        Resources
      </Text>
      <Text
        fontSize="14px"
        fontWeight={400}
        lineHeight="20px"
        mt="12px"
        color="#00000099"
      >
        Use the list below to discover materials that could be helpful on this
        topic.
      </Text>
      {question.body.resources.map((resource, index) => {
        const title =
          resource.mediaType === "article"
            ? "Article"
            : resource.mediaType === "book"
            ? "Book"
            : resource.mediaType
        const resourceUrl = resource.url.replace(/\s/g, "")

        return (
          <Flex key={index} flexDirection="column" mt="32px">
            <Text
              fontSize="14px"
              fontWeight="700"
              lineHeight="20px"
              color="#00000099"
            >
              {title} -{" "}
              {resourceUrl ? (
                <a href={resourceUrl} target="_blank" rel="noopener noreferrer">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      textAlign: "left",
                      textDecoration: "underline",
                      color: "#003E4C"
                    }}
                  >
                    {resource.title}
                  </span>
                </a>
              ) : (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#003E4C"
                  }}
                >
                  {resource.title}
                </span>
              )}
            </Text>
            {resource.description ? <Text>{resource.description}</Text> : null}
          </Flex>
        )
      })}
    </ResourcesContainer>
  )
}

const QuestionTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: #00000099;
`
const QuestionAnswer = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin: 0;
`

export function ActionStepPreviewQuestions({ questions }) {
  return (
    <OrderedList>
      {questions.map((item, index) => (
        <OrderedListItem key={index}>
          <QuestionTitle>{item.title}</QuestionTitle>
          <UnorderedList>
            {item.answers.map((answer, i) => (
              <li key={i}>
                <QuestionAnswer>{answer}</QuestionAnswer>
              </li>
            ))}
          </UnorderedList>
        </OrderedListItem>
      ))}
    </OrderedList>
  )
}
